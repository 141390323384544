.FindGroups {
  .title-container {
    margin-bottom: 1rem;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    gap: 1rem;

    .search {
      width: 100%;
      height: 3rem;
      border-radius: 0.5rem;
      border: 1px solid #eaeaea;
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .search-button {
      width: 100%;
      height: 3rem;
      border-radius: 0.5rem;
      border: 1px solid #eaeaea;
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
      background: #eaeaea;
      cursor: pointer;
    }

    .filter-button {
      width: 100%;
      height: 3rem;
      border-radius: 0.5rem;
      border: 1px solid #eaeaea;
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 500;
      background: #eaeaea;
      cursor: pointer;
    }

    .filter-options {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      gap: 1rem;

      .filter-option {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        gap: 1rem;
        font-weight: 600;
        -user-select: none;
        -webkit-user-select: none;

        input[type="checkbox"] {
          margin-right: 0rem;
          scale: 1.5;
        }
      }
    }
  }
}

.column {
  .groups {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    gap: 1rem;

    .group {
      background: #eaeaea;
      border-radius: 1rem;
      padding: 1rem;

      .group-info {
        .group-header {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          .group-name {
            font-size: 2rem;
            font-weight: 700;
            margin-right: 3rem;
          }
        }
      }

      .group-buttons {
        display: flex;
        align-items: center;

        button.primary {
          margin-left: auto;
          font-size: 1.25rem;
          padding: 0rem 0.5rem;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
