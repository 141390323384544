.Register {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: calc(100dvh - 60px);
  padding: 1rem;
  gap: 3rem;

  .title-container {
    max-width: 30%;

    .title {
      font-size: 5rem;

      @media (max-width: 700px) {
        font-size: 5rem;
      }

      @media (max-width: 530px) {
        font-size: 4rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: unset;

    .title-container {
      margin-bottom: 2rem;
      max-width: unset;
      width: clamp(300px, 100%, 500px);
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    width: clamp(300px, 100%, 500px);

    .register-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      background-color: var(--bg-secondary);
      border-radius: 1rem;
      padding: 1rem;
      font-size: 1.5rem;

      .form-header {
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
          width: 40px;
        }

        .title {
          font-size: 2rem;
          font-weight: 700;
          margin-left: 1rem;
        }
      }

      label {
        font-weight: 600;
        display: flex;
        gap: 0rem;
        flex-direction: column;

        input {
          background-color: var(--background-color);
          border: 1px solid var(--font-color);
          border-radius: 5px;
          font-size: 1.25rem;
          padding: 0.25rem 0.5rem;
        }
      }

      .courses {
        .course {
          font-size: 1.1rem;
          font-weight: 600;
          border: 2px solid var(--font-color);
          border-radius: 5px;
          margin-right: 0.5rem;
          position: relative;

          &:hover {
            color: var(--error);

            // draw X on hover
            &::after {
              content: "X";
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.5rem;
              font-weight: 700;
            }
          }
        }
      }

      p.error {
        color: var(--error);
        font-size: 1.25rem;
        text-align: center;
      }

      .support {
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
      }
    }
  }
}
