.Header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  // background-color: #1a242c;121f25
  background: linear-gradient(90deg, #1a242c 0%, #101b21 100%);
  z-index: 1;
  color: var(--font-white);
  border-bottom: 1px solid #000;
  transition: all 0.05s linear;

  @media (prefers-color-scheme: dark) {
    &.scrolled {
      border-bottom: 1px solid #fff;
    }
  }

  .logo-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    .logo {
      width: 35px;
      height: 35px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      // text-decoration: ;
    }
  }

  .links {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;

    a {
      font-size: 1.2rem;
      font-weight: 600;
    }

    @media (max-width: 530px) {
      display: none;
    }
  }

  button.menu-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 40px;

    .menu-icon {
      width: 36px;
      height: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .menu-bar {
        width: 100%;
        height: 3px;
        background-color: var(--font-white);
        border-radius: 5px;
      }
    }

    @media (max-width: 530px) {
      display: flex;
    }
  }

  .menu {
    position: fixed;
    top: -5dvh;
    left: 0;
    width: 100%;
    height: 110dvh;
    background-color: var(--background-color);
    color: var(--font-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    transition: all 0.2s ease;

    transform: translateY(-5%);
    opacity: 0;
    visibility: hidden;

    &.open {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    button.close-menu {
      position: fixed;
      top: calc(5dvh + 20px);
      right: 20px;
      width: 60px;
      height: 60px;
      font-size: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      font-size: 1.7rem;
      font-weight: 600;
    }
  }
}
