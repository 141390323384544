.NotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100dvh;
  background-color: #000;

  h1 {
    color: #ccc;
    font-size: 5rem;
    margin: 0;
  }

  p {
    color: #ccc;
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
  }

  .rainbow {
    background: linear-gradient(
      120deg,
      #ff0000,
      #ff8000,
      #ffff00,
      #80ff00,
      #00ffff,
      #0080ff,
      #0000ff,
      #8000ff,
      #ff00ff,
      #ff0080,
      #ff0000,
      #ff8000,
      #ffff00,
    );
    background-size: 300% 300%;
    animation: rainbow 4s linear infinite;
    font-weight: 900;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    // rotate background by 45deg
  }

  
  
}
