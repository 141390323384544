.Home {
  .title-image {
    display: flex;

    img {
      @media (min-width: 1400px) {
        scale: 1.5;
        margin-left: 9rem;
        margin-top: 5rem;
      }

      @media (max-width: 1200px) {
        scale: 0.8;
      }
      @media (max-width: 1000px) {
        display: none;
      }

      width: 500px;
      height: 500px;
      // make image fade to transparent on the edges
      mask-image: radial-gradient(
        circle at 50% 50%,
        black 0%,
        black 50%,
        transparent 70%
      );
      scale: 1.1;
    }
  }
}
